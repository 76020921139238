import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAsync } from '@/store/actions/authenticate';
import { selectUser } from '@/store/selectors/user';
import Typography from '@mui/material/Typography';
import Logo from "@/assets/logo.svg";
import Image from 'next/legacy/image';
import NextLink from 'next/link';
import { AppBar, Button, Grid } from '@mui/material';
import { userActions } from '@/store/actions/user';
import { useRouter } from 'next/router';
import Navigation from './Navigation';
import menu from "@/assets/menu.svg";
import {AppDispatch} from "@/store";

const TopBar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const router = useRouter();

  const isAdminPage = router.asPath.includes("/admin");

  const user = useSelector(selectUser);

  const handleLogoutClick = () => {
    dispatch(logoutAsync());
  };

  return (
    <AppBar
      className='appBar'
      sx={{background: isAdminPage ? "#C1C1C1" : ""}}
    >
      <Grid container display="flex" alignItems="center">
        <Grid item xs={6} lg={2}>
          <Grid container>
            <NextLink href="/processes" passHref legacyBehavior>
              <Grid container width="auto">
                <Image src={Logo} width={32} height={32} alt="logo"/>
                <Typography className="textLogo" variant="h2" sx={{marginLeft: "12px", marginRight: "12px", color: "#333"}} display="flex" alignItems="center">
                  thoughtful
                </Typography>
              </Grid>
            </NextLink>
            <Image src={menu} width={24} height={24} alt="menu" className='hamburger' onClick={() => dispatch(userActions.setDrawerMenuOpen())} />
          </Grid>
        </Grid>
        <Grid item container xs={8} sx={{background: "#EBEEF1", borderRadius: "40px", height: "40px", padding: "4px"}} className="topBar">
          <Navigation />
        </Grid>
        <Grid item xs={6} lg={2}>
          <Grid container display="flex" alignItems="center" justifyContent="flex-end" alignSelf="flex-end">
            <Typography variant="subtitle2" className='textLogo'>Hello, {user?.firstName}</Typography>
            <Button variant="outlined" size="small" sx={{ ml: "1em" }} onClick={handleLogoutClick}>Logout</Button>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  )
};

export default TopBar;
