import { logoutAsync } from '@/store/actions/authenticate';
import { selectIsUserPending, selectIsUserSessionValid } from '@/store/selectors/user';
import { Button, Dialog, Grid, Typography } from '@mui/material'
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store';

const SessionExpiredModal = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [isSessionExpiredOpen, setIsSessionExpiredOpen] = useState(false);
  const isSessionValid = useSelector(selectIsUserSessionValid);
  const isUserPending = useSelector(selectIsUserPending);

  useEffect(() => {
    if(!isSessionValid && !isUserPending) setIsSessionExpiredOpen(true);
  }, [isSessionValid, isUserPending])

  return (
    <Dialog open={isSessionExpiredOpen}>
      <Grid container spacing={4} rowSpacing={4}>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12}>
          <Typography variant="h2" textAlign="center">Your session has expired</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant='body1' textAlign="center">You will be redirected to the login page</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={() => dispatch(logoutAsync())}>OK</Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default SessionExpiredModal
